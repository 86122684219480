import React, {useContext, useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import '../css/BuildingView.css';
import BuildingSvg from './svg/BuildingSvg.js';
import AppDataContext from "./context/AppDataContext";
import BuildingLabel from "./BuildingLabel";
import {buildingConst} from "../constant/buildingConst";
import {appConst} from "../constant/appConst";
import {Image} from "react-img-placeholder";
import FavContext from "./context/FavContext";
import ClientContext from "./context/ClientContext";
import {useTranslation} from "react-i18next";

function BuildingView(props) {

	const {appData, setAppData} = useContext(AppDataContext);
	const {favs, setFavs} = useContext(FavContext);
	const {client,setClient} = useContext(ClientContext);
	const { t } = useTranslation();
	const [buildingType,setBuildingType] = useState("");
	const [isVilla,setIsVilla] = useState(false);
	const [buildingCode,setBuildingCode] = useState("");
	const [labels,setLabels] = useState([]);
	let buildingId = parseInt(props.location.pathname.split("/").pop());


	useEffect(() => {
		if(appData.loaded)
		{

			let updatedLabel = [];
			let favProps = [];

			favs.forEach((fav) => {
				favProps.push(fav.property.number)
			})
			appData.buildings.map(building => {
				if(building.id === buildingId){

					setBuildingCode(building.code)
					setBuildingType(building.class_name)
					setIsVilla(building.is_villa);
					let properties = getPropertyByBuilding()
					if(!building.is_villa){

						console.log("favs",favProps)
						for (let i = 0; i <=6; i++) {

							updatedLabel.push(
								<BuildingLabel key={i} propId={i}  isFav={getFavByFlatLevel(i,favProps)} title={i === 0 ? t("building_view.floor_g") : t("building_view.floor") + " " + i}  total={getTotalFilteredPropertyByFlatLevel(i)} />
							);
						}
						/*if(properties.length){
							buildingConst[building.class_name].floorPath.forEach(function(path){
								properties.forEach(function(property,i){
									if(path.level === property.flat_level)
										updatedLabel.push(

									<BuildingLabel key={i} propId={property.id} isFav={favProps.includes(property.code)} title={property.code}  total={isPropertyInFiltered(property.id)} />
										);

								})
							});
						}*/

					}
					else {


						if(properties.length){
							buildingConst[building.class_name].floorPath.forEach(function(path){
								properties.forEach(function(property,i){
									if(path.number === property.number)
										updatedLabel.push(
											<BuildingLabel key={i} propId={property.id} isFav={true} title={property.code} total={isPropertyInFiltered(property.id)} />
										);
								})
							});
						}

					}

					setLabels(updatedLabel);
				};
			});
		}

	},[appData,props.loaded360,favs,client,t]);



	// For villa property
	function getPropertyByBuilding(){
		let properties = [];
		appData.properties.forEach(function(property,i){
			if(buildingId === property.building.id)
			{
				properties.push(property)
			}
		})

		return properties;
	}
	function isPropertyInFiltered(id) {
		let isInFiltered = 0;
		let activeProperties = appData.isFilterModeActive ? appData.filteredProperties : appData.properties
		activeProperties.forEach(function(property,i){
			if(id === property.id)
			{
				isInFiltered = 1;
			}
		})
		return isInFiltered
	}
	function getTotalFilteredPropertyByFlatLevel(level)
	{
		var total = 0;
		var sold = 0;
		appData.filteredProperties.forEach(function(property,i){
			if(level === property.flat_level && buildingId === property.building.id)
			{
				if(property.status === appConst.status.sold) sold++;
				total++;
			}
		})
		if(sold === total) return 0;
		return total;
	}

	function getFavByFlatLevel(level,favProps)
	{
		let isFav = false;
		appData.filteredProperties.forEach(function(property,i){
			if(level === property.flat_level && buildingId === property.building.id)
			{

				if(favProps.includes(property.code)) {
					isFav = true;
				}
			}
		})
		return isFav;
	}

	if(!appData) return null;



	return (

		<section className={"primary building " + buildingType.toLowerCase()}>
			<div className="labels">
				{(buildingType) && labels}
			</div>

			<div className="main-title" >
				<div className="number">{buildingCode}</div>
			</div>

			<Link to={"/"}>
				<button className="close-button">
					<div></div>
					<div></div>
				</button>
			</Link>

			<div className="inner">

				{ buildingType && <Image className="main-image" src={require('../img/buildings/' + buildingType.toLowerCase() + '.webp')} width="1920" height={"1080"} placeholderSrc={require('../img/placeholder.webp')}/> }

				<div className="paths">
					{buildingType && <BuildingSvg buildingId={buildingId} buildingType={buildingType} isVilla={isVilla} buildingProperties={getPropertyByBuilding()} floorStatuses={appData.floorStatuses[buildingCode]} />}

				</div>



			</div>

		

		</section>

	);
}

export default withRouter(BuildingView);