import React from "react";
import i18next from 'i18next';

const appSettings = {
    projectName: "Smyrina Park Nefes",
    projectId : 1,
    currencyUnit: "₺",
    currencyUnitPosition: "before",
    areaUnit: <>m<sup>2</sup></>,
    showPrices: true,
    priceFilter: {
        unit: 1000000,
        min: 500000000,
        max: 2000000000,
    },
    showIntroVideo : false,
    is360External : false,
    clientVerification : 0,
    showRegisterClientPage : true,
    botSalesRep : "online@ideayapi.com",
    isMultiCanvas : false,
    has360 : false,
    hasInfo : false,
    hasRealShot : true
};

i18next.on('languageChanged', function(lng) {

    appConst.menu.gallery = [
        {id:1, title: i18next.t("gallery.exterior"),isExternal : false,link: '/gallery/exterior'},
        {id:2, title: i18next.t("gallery.interior"),isExternal : false,link: '/gallery/interior'},
        {id:3, title: i18next.t("gallery.social"),isExternal : false,link: '/gallery/social'},
        {id:4, title: i18next.t("gallery.brochure"),isExternal : false,link: '/gallery/brochure'},
    ];

    appConst.menu.location_category = [
        {id : "nearby",title : i18next.t("location.nearby")},
        {id : "transportation",title : i18next.t("location.transportation")},
        {id : "schools",title : i18next.t("location.schools")},
        {id : "health",title : i18next.t("location.health")},
    ]

    appConst.gallery = {

        exterior : [
            {caption:i18next.t("gallery.exterior") + ' 1', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 2', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 3', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 4', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 5', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 6', source: require("../img/gallery/exterior/i6.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 7', source: require("../img/gallery/exterior/i7.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 8', source: require("../img/gallery/exterior/i8.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 9', source: require("../img/gallery/exterior/i9.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 10', source: require("../img/gallery/exterior/i10.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 11', source: require("../img/gallery/exterior/i11.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 12', source: require("../img/gallery/exterior/i12.jpg")},
        ],
            interior : [
            {caption:i18next.t("gallery.interior") + ' 1', source: require("../img/gallery/interior/i1.jpg")},
            {caption:i18next.t("gallery.interior") + ' 2', source: require("../img/gallery/interior/i2.jpg")},
            {caption:i18next.t("gallery.interior") + ' 3', source: require("../img/gallery/interior/i3.jpg")},
            {caption:i18next.t("gallery.interior") + ' 4', source: require("../img/gallery/interior/i4.jpg")},
            {caption:i18next.t("gallery.interior") + ' 5', source: require("../img/gallery/interior/i5.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i6.jpg")},

        ],

            social : [
            {caption:i18next.t("gallery.interior"), source: require("../img/gallery/social/i1.jpg")},
            {caption:i18next.t("gallery.pool"), source: require("../img/gallery/social/i2.jpg")},
            {caption:i18next.t("gallery.jacuzzi"), source: require("../img/gallery/social/i3.jpg")},
            {caption:i18next.t("gallery.cafe"), source: require("../img/gallery/social/i4.jpg")},
            {caption:i18next.t("gallery.sauna"), source: require("../img/gallery/social/i5.jpg")},
            {caption:i18next.t("gallery.spa"), source: require("../img/gallery/social/i6.jpg")},
            {caption:i18next.t("gallery.gym"), source: require("../img/gallery/social/i7.jpg")},

        ]
    }
})
const appConst = {
    
    menu: {
        gallery : [
            {id:1, title: i18next.t("gallery.exterior"),isExternal : false,link: '/gallery/exterior'},
            {id:2, title: i18next.t("gallery.interior"),isExternal : false,link: '/gallery/interior'},
            {id:3, title: i18next.t("gallery.social"),isExternal : false,link: '/gallery/social'},
            {id:4, title: i18next.t("gallery.brochure"),isExternal : false,link: '/gallery/brochure'},
        ],

        location_category : [
            {id : "nearby",title : i18next.t("location.nearby")},
            {id : "transportation",title : i18next.t("location.transportation")},
            {id : "schools",title : i18next.t("location.schools")},
            {id : "health",title : i18next.t("location.health")},
        ],

        location : {
            center : [
                {id:0, title:"", lat: 38.53749667304882,  lng:27.03404151382979}
            ],

            nearby : [
                {id:1,title:"Mavişehir AVM'ler Bölgesi", link :"/location/nearby/1",lat:38.47376231500454 , lng:27.074358075560816},
                {id:2,title:"Atatürk Organize Sanayi Bölgesi", link :"/location/nearby/2",lat:38.4926140796802 , lng:27.02644908089457},
                {id:3,title:"Karşıyaka Merkez", link :"/location/nearby/3",lat:38.51839863792482, lng:27.138265277717537},
                {id:4,title:"Bayraklı Merkez", link :"/location/nearby/4",lat:38.46330812315461 , lng:27.167127617233476},
                {id:5,title:"Alsancak Merkez", link :"/location/nearby/5",lat:38.437984696524424 , lng:27.14269154289636},
                {id:6,title:"Aliağa", link :"/location/nearby/6",lat:38.821155827615044 , lng:27.055935271625547},
                {id:7,title:"Foça", link :"/location/nearby/7",lat:38.6703667596055 , lng:26.757923554268153},
                {id:8,title:"İzmir Doğal Yaşam Parkı", link :"/location/nearby/8",lat:38.49100634924674 , lng:26.963696280505346},
            ],

            transportation : [
                {id:9,title:"İzmir Adnan Menderes Havalimanı", link :"/location/transportation/9",lat:38.29199430345762 , lng:27.14895151564365},
                {id:10,title:"Ulukent İzban İstasyonu", link :"/location/transportation/10",lat:38.54680767640766 , lng:27.03512207770201},
            ],

            schools : [
                {id:11,title:"Sınav Koleji", link :"/location/schools/11", lat:38.514943102372875 , lng:27.042732851839215},
            ],

            health : [
                {id:12,title:"Çiğli Bölge Eğitim Hastanesi", link :"/location/health/12",lat:38.49938943503446 , lng:27.050836898413134},
                {id:13,title:"Özel Ekol Hastanesi", link :"/location/health/13",lat:38.48541151277879, lng:27.05999765353215},
                {id:14,title:"Özel Kent Hastanesi", link :"/location/health/14",lat:38.4899495451214 , lng:27.05198971604356},

            ],

        }


    },
    gallery : {

        exterior : [
            {caption:i18next.t("gallery.exterior") + ' 1', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 2', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 3', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 4', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 5', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 6', source: require("../img/gallery/exterior/i6.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 7', source: require("../img/gallery/exterior/i7.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 8', source: require("../img/gallery/exterior/i8.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 9', source: require("../img/gallery/exterior/i9.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 10', source: require("../img/gallery/exterior/i10.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 11', source: require("../img/gallery/exterior/i11.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 12', source: require("../img/gallery/exterior/i12.jpg")},
        ],
        interior : [
            {caption:i18next.t("gallery.interior") + ' 1', source: require("../img/gallery/interior/i1.jpg")},
            {caption:i18next.t("gallery.interior") + ' 2', source: require("../img/gallery/interior/i2.jpg")},
            {caption:i18next.t("gallery.interior") + ' 3', source: require("../img/gallery/interior/i3.jpg")},
            {caption:i18next.t("gallery.interior") + ' 4', source: require("../img/gallery/interior/i4.jpg")},
            {caption:i18next.t("gallery.interior") + ' 5', source: require("../img/gallery/interior/i5.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i6.jpg")},

        ],

        social : [
            {caption:i18next.t("gallery.interior"), source: require("../img/gallery/social/i1.jpg")},
            {caption:i18next.t("gallery.pool"), source: require("../img/gallery/social/i2.jpg")},
            {caption:i18next.t("gallery.jacuzzi"), source: require("../img/gallery/social/i3.jpg")},
            {caption:i18next.t("gallery.cafe"), source: require("../img/gallery/social/i4.jpg")},
            {caption:i18next.t("gallery.sauna"), source: require("../img/gallery/social/i5.jpg")},
            {caption:i18next.t("gallery.spa"), source: require("../img/gallery/social/i6.jpg")},
            {caption:i18next.t("gallery.gym"), source: require("../img/gallery/social/i7.jpg")},

        ]
    },
    brochure : [
        {image: require("../img/brochure/i1.webp")},
        {image: require("../img/brochure/i2.webp")},
        {image: require("../img/brochure/i3.webp")},
        {image: require("../img/brochure/i4.webp")},
        {image: require("../img/brochure/i5.webp")},
        {image: require("../img/brochure/i6.webp")},
        {image: require("../img/brochure/i7.webp")},
        {image: require("../img/brochure/i8.webp")},
        {image: require("../img/brochure/i9.webp")},
        {image: require("../img/brochure/i10.webp")},
        {image: require("../img/brochure/i11.webp")},
        {image: require("../img/brochure/i12.webp")},
        {image: require("../img/brochure/i13.webp")},
        {image: require("../img/brochure/i14.webp")},
        {image: require("../img/brochure/i15.webp")},
        {image: require("../img/brochure/i16.webp")},
        {image: require("../img/brochure/i17.webp")},
        {image: require("../img/brochure/i18.webp")},
        {image: require("../img/brochure/i19.webp")},
        {image: require("../img/brochure/i20.webp")},
        {image: require("../img/brochure/i21.webp")},
        {image: require("../img/brochure/i22.webp")},
        {image: require("../img/brochure/i23.webp")},
        {image: require("../img/brochure/i24.webp")},
        {image: require("../img/brochure/i25.webp")},
        {image: require("../img/brochure/i26.webp")},
        {image: require("../img/brochure/i27.webp")},
        {image: require("../img/brochure/i28.webp")},
        {image: require("../img/brochure/i29.webp")},
        {image: require("../img/brochure/i30.webp")},
        {image: require("../img/brochure/i31.webp")},
        {image: require("../img/brochure/i32.webp")},
        {image: require("../img/brochure/i33.webp")},
        {image: require("../img/brochure/i34.webp")},
        {image: require("../img/brochure/i35.webp")},
        {image: require("../img/brochure/i36.webp")},
        {image: require("../img/brochure/i37.webp")},
        {image: require("../img/brochure/i38.webp")},
        {image: require("../img/brochure/i39.webp")},
        {image: require("../img/brochure/i40.webp")},
        {image: require("../img/brochure/i41.webp")},
        {image: require("../img/brochure/i42.webp")},
        {image: require("../img/brochure/i43.webp")},
        {image: require("../img/brochure/i44.webp")},
        {image: require("../img/brochure/i45.webp")},
        {image: require("../img/brochure/i46.webp")},


    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        blocked : 4,
        offer : 5,
        signed : 6,
        issue : 7,
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Blocked";
        if(id === 5)  str = "Offer / Negotiation";
        if(id === 6)  str = "Signed Contract";
        if(id === 7)  str = "Issue";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    lead_status : {
        new : 1,
        active : 2,
        lost : 3,
        inactive : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};

export {
    appSettings,
    appConst,
};