import React, {useContext} from 'react';
import "../css/FilterTag.css"
import FilterContext from "./context/FilterContext";
import {appSettings} from "../constant/appConst";
function FilterTag(props) {

    const { filter, setFilter } = useContext(FilterContext);

    function clear(){
        let newFilter = {...filter};
        if(props.type === "status") {
            newFilter.status = false;
        }
        else if(props.type === "buildingType") {

            const index = newFilter.buildingType.indexOf(props.buildingId);
            if (index > -1) {
                newFilter.buildingType.splice(index, 1);
            }
            else {
                newFilter.buildingType.push(props.buildingId);
            }

        }
        else if(props.type === "priceMin") {
            newFilter.priceMin = appSettings.priceFilter.min;
            props.stateChanger(appSettings.priceFilter.min / appSettings.priceFilter.unit);
        }
        else if(props.type === "priceMax") {
            newFilter.priceMax = appSettings.priceFilter.max;
            props.stateChanger(appSettings.priceFilter.max / appSettings.priceFilter.unit);
        }
        else if(props.type === "floorType") {

            const index = newFilter.floorType.indexOf(props.floorId);
            if (index > -1) {
                newFilter.floorType.splice(index, 1);
            }
            else {
                newFilter.floorType.push(props.floorId);
            }

        }
        setFilter(newFilter);
    }
    return (
        <span className={"filter-tag mr-1 mb-2"}>
            {props.text}
            <button className="delete-button" onClick={() => clear(props.type)} >
                <div></div>
                <div></div>
            </button>
        </span>
    );
}

export default FilterTag;